import { gql } from '@apollo/client';
import { AiSummaryState, ProfileAiSummaryData } from '@graphql/generated/types/graphqlTypes';
import { IMAGE_FRAGMENT } from '../fragments/global';
import {
  ABSTRACT_PROFILE_FRAGMENT,
  PROFILE_PAID_FEATURES_FRAGMENT,
  PROFILE_SHOWCASE_TILE_FRAGMENT,
  PROFILE_SHOWCASE_TILE_VISUAL_CONTENT_ITEM_FRAGMENT,
} from '../fragments/profile';

const EXTENDED_ABSTRACT_PROFILE_FRAGMENT = gql`
  fragment ExtendedAbstractProfileFragment on AbstractProfile {
    ...AbstractProfileFragment
    b2bReportAccessHash
    highestActiveContract {
      type
    }
    paidFeatures {
      ...ProfilePaidFeaturesFragment
    }
    allOutlinks {
      type
      url
      label
    }
    singleSelectedOutlinkType
  }
  ${ABSTRACT_PROFILE_FRAGMENT}
  ${PROFILE_PAID_FEATURES_FRAGMENT}
`;

export const DEFAULT_PROFILE_FOR_CURRENT_USER_IN_B2B_ADMIN = gql`
  query DefaultProfileForCurrentUserInB2bAdmin {
    defaultProfileForCurrentUserInB2bAdmin {
      id
      b2bReportAccessHash
    }
  }
`;

export const PROFILE_BY_B2B_REPORT_ACCESS_HASH = gql`
  query ProfileByB2bReportAccessHash($b2bReportAccessHash: String!) {
    profileByB2bReportAccessHash(b2bReportAccessHash: $b2bReportAccessHash) {
      ...ExtendedAbstractProfileFragment
    }
  }
  ${EXTENDED_ABSTRACT_PROFILE_FRAGMENT}
`;

export const CAN_CURRENT_USER_ACCESS_B2B_ADMIN = gql`
  query CanCurrentUserAccessB2bAdmin($b2bReportAccessHash: String!) {
    canCurrentUserAccessB2bAdmin(b2bReportAccessHash: $b2bReportAccessHash) {
      email
      isAuthorized
      companyName
    }
  }
`;

export const AVAILABLE_PROFILES_FOR_CURRENT_USER_AS_CONTACT_PERSON = gql`
  query AvailableProfilesForCurrentUserAsContactPerson {
    availableProfilesForCurrentUserAsContactPerson {
      id
      expandedName
      b2bReportAccessHash
      highestActiveContract {
        type
      }
    }
  }
`;

export const PROFILE_VISUAL_CONTENT_ITEMS = gql`
  query ProfileVisualContentItems($b2bReportAccessHash: String!, $profileVisualContentType: String!) {
    profileVisualContentItems(
      b2bReportAccessHash: $b2bReportAccessHash
      profileVisualContentType: $profileVisualContentType
    ) {
      position
      profileVisualContentItemData {
        title
        description
        image {
          ...ImageFragment
        }
        linkToYoutubeVideo
        type
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const COVER_PHOTO = gql`
  query CoverPhoto($b2bReportAccessHash: String!) {
    coverPhoto(b2bReportAccessHash: $b2bReportAccessHash) {
      type
      image {
        ...ImageFragment
      }
      title
      description
      suitableFor {
        id
        questionnaireLabel
      }
      description
      position
      linkToYoutubeVideo
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const AVAILABLE_MONTHLY_REPORTS = gql`
  query AvailableMonthlyReports($b2bReportAccessHash: String!) {
    availableMonthlyReports(b2bReportAccessHash: $b2bReportAccessHash) {
      label
      value
      isCumulative
    }
  }
`;

const B2B_REPORT_DATA_FRAGMENT = gql`
  fragment B2bReportDataFragment on ProfileB2bReportData {
    monthTimestamp
    formattedMonth
    isFreeReport
    hasMediumMetrics
    hasPremiumMetrics
    countOfTotalProfilePageviews
    premiumProfilePageviewIncreaseRate
    countOfProfilePageviewsWithoutPaidFeatures
    profilePageviewRank
    profilePageviewRankNoPremium
    countOfCompanyBrandViews
    countOfJobDescriptionViews
    countOfJobDescriptionApplications
    countOfFollowers
    timeOnProfilePageInSeconds
    countOfAddedQuestionnaires
    averageReviewValueInPercent
    payHappinessAverageReviewValueInPercent
    recommendationAverageReviewValueInPercent
  }
`;

export const MONTHLY_B2B_REPORT_DATA = gql`
  query MonthlyB2bReportData($b2bReportAccessHash: String!, $month: String!) {
    monthlyB2bReportData(b2bReportAccessHash: $b2bReportAccessHash, month: $month) {
      ...B2bReportDataFragment
    }
  }
  ${B2B_REPORT_DATA_FRAGMENT}
`;

export const GRAPH_B2B_REPORT_DATA = gql`
  query GraphB2bReportData($b2bReportAccessHash: String!) {
    graphB2bReportData(b2bReportAccessHash: $b2bReportAccessHash) {
      hasMediumMetrics
      hasPremiumMetrics
      hasGraphsReportExport
      isFreeReport
      monthlyProfileB2bReportData {
        ...B2bReportDataFragment
        naceAverageReviewValuesInPercentWithNaceCode {
          naceCode
          averageReviewValueInPercent
        }
      }
    }
  }
  ${B2B_REPORT_DATA_FRAGMENT}
`;

export const EXAMPLE_PROFILE_URL_FOR_VISUAL_CONTENT_PREVIEW = gql`
  query ExampleProfileUrlForVisualContentPreview($profileVisualContentType: String!) {
    exampleProfileUrlForVisualContentPreview(profileVisualContentType: $profileVisualContentType)
  }
`;

export const MOST_RECOMMENDED_SUITABLE_FOR_CHOICES_FOR_PROFILE = gql`
  query MostRecommendedSuitableForChoicesForProfile($b2bReportAccessHash: String!) {
    mostRecommendedSuitableForChoicesForProfile(b2bReportAccessHash: $b2bReportAccessHash) {
      id
      label
    }
  }
`;

export const PROFILE_VISUAL_CONTENT_BY_REPORT_ACCESS_HASH_AND_TYPE_AND_POSITION = gql`
  query ProfileVisualContentByReportAccessHashAndTypeAndPosition(
    $b2bReportAccessHash: String!
    $profileVisualContentType: String!
    $position: Int!
  ) {
    profileVisualContentByReportAccessHashAndTypeAndPosition(
      b2bReportAccessHash: $b2bReportAccessHash
      profileVisualContentType: $profileVisualContentType
      position: $position
    ) {
      originalImageUrl
      cropCoordinates
      multiChoiceOptionId
      title
      comment
      linkToYoutubeVideo
    }
  }
`;

export const PROFILE_SHOWCASE_TILE_BY_POSITION = gql`
  query ProfileShowcaseTileByPosition($b2bReportAccessHash: String!, $position: Int!) {
    profileShowcaseTileByPosition(b2bReportAccessHash: $b2bReportAccessHash, position: $position) {
      ...ProfileShowcaseTileFragment
    }
  }
  ${PROFILE_SHOWCASE_TILE_FRAGMENT}
`;

export const PROFILE_SHOWCASE_TILE_VISUAL_CONTENT_ITEMS_WITH_ORIGINAL_IMAGE_URL = gql`
  query ProfileShowcaseTileVisualContentItemsWithOriginalImageUrl($profileShowcaseTileId: Int!) {
    profileShowcaseTileVisualContentItemsWithOriginalImageUrl(profileShowcaseTileId: $profileShowcaseTileId) {
      originalImageUrl
      profileShowcaseTileVisualContentItem {
        ...ProfileShowcaseTileVisualContentItemFragment
      }
    }
  }
  ${PROFILE_SHOWCASE_TILE_VISUAL_CONTENT_ITEM_FRAGMENT}
`;

const getTimestampOneYearAgo = () => {
  const currentDate = new Date();
  const oneYearAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
  oneYearAgo.setHours(0, 0, 0, 0);

  return oneYearAgo.getTime();
};

export const createCommentsCountSinceDateQueryVariables = (
  b2bReportAccessHash: string,
  latestAiSummary: ProfileAiSummaryData | null | undefined,
) => {
  return {
    b2bReportAccessHash,
    startDateTimestamp:
      latestAiSummary && latestAiSummary.state === AiSummaryState.Done
        ? latestAiSummary.dateTimeOfCreationTimestamp
        : getTimestampOneYearAgo(),
  };
};

export const COMMENTS_COUNT_SINCE_DATE = gql`
  query CommentsCountSinceDate($b2bReportAccessHash: String!, $startDateTimestamp: Timestamp!) {
    commentsCountSinceDate(b2bReportAccessHash: $b2bReportAccessHash, startDateTimestamp: $startDateTimestamp)
  }
`;

export const LAST_AI_SUMMARY_FOR_COMPANY_BY_STATES = gql`
  query LastAiSummaryForCompanyByStates($b2bReportAccessHash: String!, $states: [AiSummaryState!]) {
    lastAiSummaryForCompanyByStates(b2bReportAccessHash: $b2bReportAccessHash, states: $states) {
      dateTimeOfCreationTimestamp
      state
      profileAiSummaryItems {
        isPositive
        textSummarization
        numberOfComments
        profileAiSummaryTextAnswers {
          value
          isPositive
        }
      }
    }
  }
`;
